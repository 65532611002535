import React, {useRef, useEffect, useState} from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from "@Components/Header/Header";
import { Link } from "@StarberryUtils";
import Footer from "@Components/Footer/Footer";
import BreadcrumbPage from "@Components/SearchResult/BreadcrumbPage/BreadcrumbPage";
import CustomVideoPlay from "@Components/CustomVideo/CustomVideo.js";
import PropertyDetailsBanner from "@Components/PropertyDetails/PropertyDetailsBanner/PropertyDetailsBanner";
import PropertyDescription from "@Components/PropertyDetails/PropertyDescription/PropertyDescription";
import StationLocations from "@Components/PropertyDetails/StationLocations/StationLocations";
import Calculator from "@Components/PropertyDetails/Calculator/Calculator";
import SocialShare from "@Components/Share/SocialShare";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PropertyBookConsultation from "@Components/Home/HomeBookConsultation/PropertyBookConsultation";
import SimilarProperties from "@Components/SimilarProperties/SimilarProperties";
import LogoImgF from "../images/logo-fixed.svg";
import { useQuery } from '@apollo/client';
import Modal from "react-bootstrap/Modal"
import BookAViewing from "@Components/BookAViewing/book-a-viewing.js"
import gql from "graphql-tag"
import { Container } from 'react-bootstrap';
import NotFoundPage from '../pages/404';
import { Logo } from '@Components/icon/icon'
import MortgageCalc from "@Components/calculators/mortgagecalc/index";
import SEO from "@Components/SEO/seo";
import ScrollAnimation from 'react-animate-on-scroll';
import logoBlack from "../images/logo-t.svg";
import { useStaticQuery, graphql } from "gatsby";
import { numberWithCommas } from "@Components/common/site/common-functions"
import { UserObjectStoreProvider, useUserObjectState, useAuthState, getUserobjectData } from "@starberry/myaccount-website-utils";
import RequestAViwing from "../Components/RequestAViewing/RequestAViewing";
export const uknumberformat = (x) => {
  if (!x) return x;
  const currentValue = x.toString().replace(/[^\d]/g, '');
  return `${currentValue.slice(0, 5)} ${currentValue.slice(5, 11)}`;
}
const PROPERTY_FOR_SALE =  gql`
  query ForSale ($url: String!){
    properties(where:{id: $url}) {
      id
      title
      slug
      crm_id
      crm_provider
      status
      office_crm_id
      brochure
      building
      display_address
      address
      bedroom
      accomadation_summary
      price
      latitude
      longitude
      images
      search_type
      crm_negotiator_id
      floorplan
      room_details
      accomadation_summary
      description
      studentProperty
      video_link
      long_description
      bathroom
      reception
      virtual_tour
      price_qualifier
      status
      publish
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      epc
    }
  }
`;
const PropertyDetails = () => {
  const officeContactdata = useStaticQuery(graphql`
  query{
      glstrapi {
        offices(sort: "Sort:DESC", publicationState: LIVE, where: {Publish: true}) {
          Office_Name
          Sales_Phone
          Lettings_Phone
        }
      }
  }
`);
  const detailsRef = useRef(null)
  const mapRef = useRef(null)
  const galleryRef = useRef(null)

  const detailsScroll = () => detailsRef.current.scrollIntoView({ behavior: 'smooth' })
  const mapScroll = () => mapRef.current.scrollIntoView({ behavior: 'smooth' })
  const galleryScroll = () => galleryRef.current.scrollIntoView({ behavior: 'smooth' })
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    setShow(true);
  }

  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length-1];
  id = id.replace("/", "");

  const { loading, error, data:property_details } = useQuery(PROPERTY_FOR_SALE, {
  variables: { url: id }});

  const [userObjects, setUserObjects] = useState({});
  const { state: authState, services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()


  const [scroll, setScroll] = useState(false);
  useEffect(() => {
      window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 300)
      })

      if (isAuthenticated) {
        const getUserObjects = async () => {
            try {
                const userObjects = await getUserobjectData()
                setUserObjects(userObjects.data.data)
            } catch (e) {
                console.log("e:", e)
            }
        }
        getUserObjects()
    }
  }, [])

      // Floorplan lightbox
      const [floorplanIndex, setFloorPlanIndex] = useState(0);
      const [isOpenFloor, setIsOpenFloor] = useState(false);

      const openLighboxMobileFloor = (e, ind) => {
          e.preventDefault();
          setFloorPlanIndex(ind);
          setIsOpenFloor(true);
      }

      // Floorplan lightbox


      // if (loading) return (
      //   <section className={"loader-wrapper"}>
      //     <div id="loader-wrapper">
      //       <div id="loader" class="new-loader">
      //         <div className="new-loader-anime"></div>
      //         <img className="logo-white" src={logoBlack} className="loader-logo" alt="logo" />
      //       </div>
      //       <div class="loader-section section-left"></div>
      //       <div class="loader-section section-right"></div>
      //     </div>
      //   </section>
      // );

      if (loading) return (
        <div className="static-news gql-loading-screen">
          <Container>
            <div className="gql-loading">
            <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
              </div>
          </Container>
        </div>
      );

  return (

      property_details && property_details.properties && property_details.properties.length > 0 ? property_details.properties.map((data, i) => {
        /*if(data) {
          sessionStorage.setItem('data', JSON.stringify(data))
        }*/
        try {
            sessionStorage.setItem('data', JSON.stringify(data))
        } catch (e) {
            if (e instanceof SyntaxError) {
                console.log(e.message);
            }
        }
        sessionStorage.setItem('property_url', pageurl)
      const metaTitle = data.title + ' in ' + data.display_address
        // console.log('PROPERTY_FOR_SALE', property_details);
      var imagesArr = []
      for(var i=0; i < data.images.length; i++){
        imagesArr.push(data.images[i].url)
      }
        var imagesArr1 = []
        for(var i=0; i < data?.floorplan?.length; i++){
          imagesArr1.push(data?.floorplan[i].url)
        }

        var FloorPlanImage = data?.floorplan && data?.floorplan.map(img => img.url);


      const metaDesc = 'Request a viewing for this ' + data.title + ' in ' +  data.display_address + ' with College and County at £' + numberWithCommas(data.price) +'. Contact us and get assisted for all your property needs.'


    return (
        <React.Fragment>
          <Modal
              id="share_modal_mobile"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
       		 dialogClassName="modal-90w modal-form"
            >
              <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100"><h6 className="text-center content-primary-color mb-0 text-uppercase">Request a viewing</h6></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {officeContactdata?.glstrapi?.offices?.length > 0 ?
                <BookAViewing
                price={numberWithCommas(data.price)}
                officeNumber={data.office_crm_id === 'jet_coe-COL' ? officeContactdata?.glstrapi?.offices[0]?.Sales_Phone : data.office_crm_id === 'jet_coe-CLL' ? officeContactdata?.glstrapi?.offices[1]?.Sales_Phone : data.office_crm_id === "1" || data.office_crm_id === "3" || data.office_crm_id === '4' || data.office_crm_id === '5' ? officeContactdata?.glstrapi?.offices[0]?.Lettings_Phone : data.office_crm_id === "2" ? officeContactdata?.glstrapi?.offices[1]?.Lettings_Phone : null}
                handleClose={handleClose} search_type={data.search_type} url={pageurl} address={data?.display_address}
                image={data?.images[0]?.url}
                office_crm_id={data.office_crm_id}
                />
                : null}

              </Modal.Body>
            </Modal>
            {isOpenFloor && (
                    <Lightbox
                        mainSrc={FloorPlanImage[floorplanIndex]}
                        nextSrc={FloorPlanImage[(floorplanIndex + 1) % FloorPlanImage.length]}
                        prevSrc={FloorPlanImage[(floorplanIndex + FloorPlanImage.length - 1) % FloorPlanImage.length]}
                        onCloseRequest={() => setIsOpenFloor(false)}
                        onMovePrevRequest={() =>
                            setFloorPlanIndex((floorplanIndex + FloorPlanImage.length - 1) % FloorPlanImage.length)
                        }
                        onMoveNextRequest={() =>
                            setFloorPlanIndex((floorplanIndex + 1) % FloorPlanImage.length)
                        }
                    />
                )}
          <SEO title={metaTitle} description={metaDesc} image={data?.images[0]?.url} />
            <div style={{display: scroll ? 'none' : 'block'}} className="header-logo-black"><Header/></div>
            <ScrollAnimation className={scroll ? "property-header--fixed" : ""} animateIn='fadeIn' animateOnce={true}>
                <div className="property-header">
                    <Container fluid className="d-flex justify-content-between align-items-center">
                    <div className="logo">
                    <Link to="/">
                    <picture>
                        <source media="(min-width:992px)" srcSet={LogoImgF} />
                        <source media="(min-width:768px)" srcSet={LogoImgF} />
                        <img src={LogoImgF} alt="Logo" />
                    </picture>
                    </Link>
                    </div>
                        <div className="navigation">
                            <ul className="d-flex">
                                <li>
                                    <a href="javascript:void(0)" onClick={detailsScroll}>Details </a>
                                </li>
                                {data?.virtual_tour ?
                            <CustomVideoPlay fixtab={true} title={data?.title} url={data?.virtual_tour}/> : null}

                                {data?.floorplan[0]?.url ? <li><a href="javascript:void(0)" onClick={(e) => openLighboxMobileFloor(e, 0)}>
                                Floorplan
                                </a></li> : null}
                                <li>
                                    <a href="javascript:void(0)" onClick={mapScroll}>Map</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" onClick={galleryScroll}>Gallery</a>
                                </li>
                                {data?.brochure?.length > 0 &&
                                <li>
                                    <a target="_blank" href={data?.brochure?.length > 0 && data?.brochure[0]?.srcUrl}>Brochure</a>
                                </li>
                                }
                                {/* <li>
                                    {props?.property_floorplan?.length > 0 ?
                                        <FloorPlanModal floorplanimg={props.property_floorplan[0].url} />
                                        : ''}
                                </li>
                                <li>
                                    <Link to="/book-a-viewing">Request a viewing</Link>
                                </li> */}
                            </ul>

                        </div>
                        {/* {JSON.stringify(data?.brochure)} */}
                        <SocialShare data={data} propDetails={true} Title="Share" />
                        <div className="button-groups-property-banner d-none d-lg-block">
                           <a href="javascript:void(0)" onClick={handleShow} className="btn btn-primary">Request a viewing</a>
                           {data?.crm_negotiator_id?.Telephone?.value ? <Link href={"tel:"+data?.crm_negotiator_id?.Telephone?.value} className="btn btn-outline"><i class="icon-phone-menu"></i> Call {uknumberformat(data?.crm_negotiator_id?.Telephone?.value)}</Link> : null}
                           </div>
                    </Container>
                </div>
            </ScrollAnimation>

            {officeContactdata?.glstrapi?.offices?.length > 0 ?
            <PropertyDetailsBanner
            price={numberWithCommas(data.price)}
            image={data?.images[0]?.url}
            officeNumber={data.office_crm_id === 'jet_coe-COL' ? officeContactdata?.glstrapi?.offices[0]?.Sales_Phone : data.office_crm_id === 'jet_coe-CLL' ? officeContactdata?.glstrapi?.offices[1]?.Sales_Phone : data.office_crm_id === "1" || data.office_crm_id === "3" || data.office_crm_id === '4' || data.office_crm_id === '5' ? officeContactdata?.glstrapi?.offices[0]?.Lettings_Phone : data.office_crm_id === "2" ? officeContactdata?.glstrapi?.offices[1]?.Lettings_Phone : null}
              search_type={data.search_type} pageurl={pageurl} data={data} userObjects={userObjects} office_crm_id={data.office_crm_id}
              />
              : null}
            <PropertyDescription galleryRef={galleryRef}  detailsRef={detailsRef} postcode={data.address?.postcode?.split(" ")[0]} data={data} property_latitude={data?.latitude} property_longitude={data.longitude}/>
            <StationLocations mapRef={mapRef} property_latitude={data?.latitude} property_longitude={data.longitude}/>
            {data.search_type === 'sales' &&
            <MortgageCalc class="page-calculator grey"/>
                              }
                              {/* {data.office_crm_id} */}
{officeContactdata?.glstrapi?.offices?.length > 0 ?            <PropertyBookConsultation
price={numberWithCommas(data.price)}
officeNumber={data.office_crm_id === 'jet_coe-COL' ? officeContactdata?.glstrapi?.offices[0]?.Sales_Phone : data.office_crm_id === 'jet_coe-CLL' ? officeContactdata?.glstrapi?.offices[1]?.Sales_Phone : data.office_crm_id === "1" || data.office_crm_id === "3" || data.office_crm_id === '4' || data.office_crm_id === '5' ? officeContactdata?.glstrapi?.offices[0]?.Lettings_Phone : data.office_crm_id === "2" ? officeContactdata?.glstrapi?.offices[1]?.Lettings_Phone : null}
 search_type={data.search_type} pageurl={pageurl} data={data}
 image={data?.images[0]?.url}
            />
            : null }
            {/* {JSON.stringify(data.address)} */}
            <SimilarProperties address={data.address?.address2 ? data.address?.address2 : data.address?.town ? data.address?.town : ""} pid={data.id} price={data.price} search_type={data.search_type} display_address={data.display_address} />
            {/* <div className="breadcrumb-other-page"><BreadcrumbPage /></div> */}
            <Footer searchtype="details" property_type={data.search_type} property_display_address={data.display_address} property_building={data.building} property_address = {data.address}/>
            <RequestAViwing Request_A_Viewing_CTA={true} handleShow={handleShow} Book_A_Viewing_CTA={true} />
        </React.Fragment>
        )
      }) : <Router>
      <Switch><Route component={NotFoundPage} />
      </Switch>
      </Router>
    )
}

export default PropertyDetails
